<template>
  <div class="loan_item">
    <div class="inner inner1">
      <!-- <div class="content">
        <div class="left_cell">
          <h2>Are you in need of quick <br>and convenient cash? </h2>
          <p class="inner1_text">Look no further -  CréditoRápido is here to help you. We understand that unexpected expenses can arise at any time, and sometimes you just need a little extra financial support.
          </p>
        </div>
        <div class="right_cell">
          <img src="~@/assets/images/loanImg/about_us.png">
        </div>
      </div> -->
    </div>
    <div class="inner inner2">
      <h1>Acerca de Nosotros</h1>
      <div>
        <p>En CréditoRápido, nos dedicamos a proporcionar soluciones financieras rápidas y confiables para nuestros clientes en México.Nuestra misión es ofrecer acceso rápido y justo al crédito a través de una plataforma digital segura y fácil de usar.
        </p>
        <p>Nuestra Misión</p>
        <p>En un mundo donde las necesidades financieras pueden surgir en cualquier momento, en CréditoRápido nos esforzamos por brindar a nuestros clientes una alternativa ágil y accesible a las instituciones financieras tradicionales. Creemos que todos merecen acceso a servicios financieros justos y transparentes, independientemente de su historial crediticio.</p>
        <p>Nuestros Valores</p>
        <p>En CréditoRápido, nos regimos por los siguientes valores fundamentales:</p>
        <p>Transparencia: Creemos en la honestidad y la transparencia en todas nuestras operaciones. Nos comprometemos a proporcionar a nuestros clientes información clara y comprensible en todo momento.</p>
        <p>Responsabilidad: Nos tomamos muy en serio nuestra responsabilidad hacia nuestros clientes y la sociedad en general. Nos esforzamos por garantizar que nuestros productos y servicios sean responsables y éticos.</p>
        <p>Innovación: Buscamos constantemente nuevas formas de mejorar y optimizar nuestros servicios para satisfacer las necesidades cambiantes de nuestros clientes.</p>
      </div>
    </div>
    <div class="inner inner3">
      <h1>¡Contáctanos!</h1>
      <h2>En CréditoRápido, valoramos la comunicación abierta y estamos aquí para responder tus preguntas, atender tus inquietudes o recibir tus comentarios. No dudes en ponerte en contacto con nosotros a través de cualquiera de los siguientes medios.</h2>
      <div class="content flex jc-sb">
        <!-- background: 'url(' + item.img + ')',backgroundSize:'100% 100%', -->
        <div v-for="(item,index) in inner_3items" :key="index" :style="{ width:'32%'}">
          <div class="item flex ai-c fd-c jc-c">
            <img :src="item.img2">
            <div>
              <p>{{ item.txt }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      inner_3items: [
        { title: 'Email:', txt: 'Correo electrónico: \n apoyo@creditorapidoapp.com', img: require('@/assets/images/loanImg/innner3_bg2.png'), img2: require('@/assets/images/loanImg/email.png') },
        { title: 'Address:', txt: 'DIRECCIÓN: \n Río Amazonas 78, Col. Renacimiento, Cuauhtémoc, 06500 Ciudad de México, CDMX', img: require('@/assets/images/loanImg/innner3_bg1.png'), img2: require('@/assets/images/loanImg/loa.png') },
        { title: 'Phone：', txt: 'Horario de atención: \n Lunes a Viernes 9:00 am - 6:00 pm', img: require('@/assets/images/loanImg/innner3_bg3.png'), img2: require('@/assets/images/loanImg/time.png') }
      ]
    }
  },
  methods: {
    downloadApp() {
      // window.open('https://play.google.com/store/apps/details?id=we.prestamos.rapido')
    }
  }
}
</script>

<style lang="scss" scoped>
.loan_item{
  .inner{
    width:1400px;
    // padding:50px calc((100% - 1100px) / 2) 50px;
    margin: 0 auto;
    font-family: Arial, Arial;
    h3{
      font-size: 18px;
      color: #333;
    }
    .content{
      padding: 0px calc((100% - 1100px) / 2) 0;
      display: flex;
    }
    .left_cell{
      float: left;
      padding: 60px 0;
      text-align: left;
      h1{
        font-size: 40px;
        margin-bottom: 30px;
      }
      .inner1_text{
        color: #333;
        line-height: 25px;
        margin: 40px 0;
      }
      p{
        font-size: 12px;
        line-height: 30px;
        color: #999999;
      }
      .cell_item{
        width: 267px;
        height: 140px;
        margin-top: 18px;
        border-bottom: 1px solid #EE7E01;
      }
    }
  }
  .bottom-20{
    margin-bottom: 20px;
  }
  .inner1{
    background: url("~@/assets/images/loanImg/inner1_2.png") no-repeat;
    height: 500px;
    background-size: 100% auto;
  }
  .inner2{
    // height: 420px;
    // background:#EEEEEE;
    padding-bottom: 50px;
    h1{
      font-size: 35px;
      text-align: center;
      margin:80px 0 30px;
    }
    div{
      padding: 0 250px;
      p{
        font-size: 17px;
        line-height: 28px;
        margin-bottom: 30px;
      }
    }
  }
  .inner3{
    background: #F5F7F4;
    padding: 50px 0 100px;
    text-align: center;
    h1{
      font-size: 40px;
      margin:60px 0 30px;
    }
    h2{
      padding: 0 240px;
      font-family: Arial, Arial;
      font-weight: normal;
      line-height: 30px;
    }
    h3{
      margin-bottom: 18px;
    }
    .content{
      margin-top: 80px;
      .item{
        background: #FFFFFF;
        border-radius: 15px;
        opacity:0.9;
        // margin: 180px 15px 20px 15px;
        padding: 0px 35px;
        height: 170px;
        img{
          width: 35px;
          margin-bottom: 15px;
        }
        p{
          line-height: 25px;
        }
      }
    }
  }
}
@media only screen and (max-width: 665px){
  .loan_item{
    // padding: 30px 0;
    background: white !important;
    color: #333;
    .inner{
      width: 100vw;
      min-width: calc(100vw) !important;
    }
    .inner1{
      height: 150px !important;
    }
    .inner2{
      width: calc(100vw);
      padding-bottom: 0;
      h1{
        line-height: 40px;
        margin: 0 0 30px;
        font-size: 25px;
      }
      div{
        padding: 0 20px;
        p{
          font-size: 12px;
        }
      }
    }
    .inner3{
      padding: 20px 0 0;
      h1{
        margin: 20px 0;
      }
      h2{
        font-size: 14px;
        padding: 0 20px;
      }
      .content{
        justify-content: center !important;
        flex-direction: column !important;
        align-items: center !important;
        margin-top: 50px;
        div{
          width: 90% !important;
          margin-bottom: 20px;
        }
        .item{
          // margin: 150px 15px 15px 15px;
          margin: 0px auto;
          height: 280px;
          padding: 0;
        }
      }
    }
  }
}
</style>
